.advantage__container {
  margin: 3.5rem 0;
  line-height: 1.6;
  background: rgb(127, 126, 126);
}

.advantage__section {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding-top: 1.5rem;
  background-image: url('./assets/background-top.png');
  background-repeat: no-repeat;
  background-size: cover;
}

.advantage__header-text {
  text-align: center;
  color: var(--white);
  font-family: 'Museo-Sans';
  font-size: 24px;
  letter-spacing: 4px;
}

.advantage__chip {
  padding: 0.5rem 1.5rem;
  background-color: var(--dark-red);
  font-family: 'Museo-Sans';
  border-radius: 28px;
  color: var(--white);
  width: fit-content;
}

.advantage__list-wrapper {
  text-align: center;
  margin: 2rem 0;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.advantage__desc {
  padding: 0.5rem 2rem 1.5rem;
  font-size: 16px;
  font-family: 'Myriad-Regular';
}

.process__order-section {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background-color: rgb(58, 58, 58);
}

.process__chip {
  margin: -1.2rem 0 0;
  padding: 0.5rem 1.5rem;
  background-color: var(--dark-red);
  font-family: 'Museo-Sans';
  border-radius: 28px;
  color: var(--white);
  width: fit-content;
}

.process__list {
  display: flex;
  width: 220px;
  justify-content: flex-start;
  margin: 2rem 0 0;
}

.round__number {
  display: block;
  min-width: 40px;
  height: 40px;
  font-size: 16px;
  font-family: 'Museo-Sans';
  color: var(--white);
  padding: 0.5rem;
  background-color: var(--main-black);
  border-radius: 50%;
  text-align: center;
  margin-right: 1.5rem;
}

.process__img-wrapper {
  font-size: 16px;
  font-family: 'Museo-Sans';
  color: var(--white);
  text-align: center;
}

.process__list-wrapper .process__list:nth-child(2) .process__img-wrapper,
.process__list-wrapper .process__list:nth-child(3) .process__img-wrapper {
  margin-left: -4rem;
}

.process__list-wrapper .process__list:nth-child(5) .process__img-wrapper,
.process__list-wrapper .process__list:nth-child(6) .process__img-wrapper {
  margin-left: -1rem;
}

.process__list-wrapper .process__list:nth-child(7) .process__img-wrapper p {
  margin-left: -3.5rem;
}

.process__list-wrapper .process__list:nth-child(7) .process__img-wrapper img {
  width: 110px;
  height: 60px;
  margin-left: -3.5rem;
}

.process__list-wrapper .process__list:nth-child(4) .process__img-wrapper img {
  height: 110px;
}

.process__list-wrapper {
  display: flex;
  margin: 0 0 2rem 1.5rem;
  flex-direction: column;
  row-gap: 1.5rem;
}

.process__img-wrapper img {
  width: 90px;
  height: auto;
}

.machine__section {
  display: flex;
  padding: 2rem;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background-image: url('./assets/background-bottom.png');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: bottom;
}

.machine__text {
  margin-top: 1.5rem;
  text-align: center;
}

.machine__list-wrapper {
  display: flex;
  text-align: center;
  flex-direction: column;
  margin-top: 1.5rem;
}

.machine__list-wrapper span {
  font-size: 18px;
  color: var(--white);
  font-family: 'Museo-Sans';
}

.advantage__headline-text {
  margin-bottom: -3.5rem;
}

@media screen and (min-width: 1023px) {
  .advantage__container {
    margin-top: 0;
  }
  .advantage__desc {
    padding: 0 18rem;
    margin: 1rem 0 2rem;
  }
  .advantage__list-wrapper {
    margin-top: 4rem;
  }

  .process__img-wrapper img {
    width: 90px;
    height: 90px;
  }
}

@media screen and (min-width: 767px) {
  .process__list:last-child {
    grid-column-start: 2;
    grid-column-end: 3;
  }
  .process__list-wrapper .process__list:nth-child(4) .process__img-wrapper img {
    width: 80px;
    height: 90px;
  }
  .process__list-wrapper {
    display: grid;
    margin-top: 2rem;
    gap: 2rem;
    grid-template-columns: repeat(3, 1fr);
  }
}
