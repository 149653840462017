.modal {
  display: flex;
  align-items: center;
  bottom: 0;
  padding: 0;
  overflow: hidden;
  position: absolute;
  pointer-events: none;
  top: 0;
  width: 100%;
}

.modal.modal__visible {
  pointer-events: visible;
  overflow: hidden;
  position: fixed;
  height: 100vh;
  z-index: 999;
}

.modal .modal__mask {
  display: none;
}

.modal.modal__visible .modal__mask {
  display: block;
  z-index: 99;
}

.modal__mask {
  background: rgba(0, 0, 0, 0.4);
  bottom: 0;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  z-index: 999;
  width: 100%;
}

.modal__close-btn {
  display: flex;
  justify-content: flex-end;
}

.modal__close-btn span {
  cursor: pointer;
}

.modal__body {
  background: #fff;
  border-radius: 6px;
  padding: 1rem;
  margin: 0 1rem;
  height: fit-content;
  width: 100%;
  z-index: 999;
  display: flex;
  flex-direction: column;
  overflow: hidden;
}

@media screen and (min-width: 1023px) {
  .modal__body {
    width: 50%;
    margin: 0 auto;
    min-height: 320px;
  }
}
