.services__container {
  padding: 2rem;
  text-align: center;
  background: rgb(71, 71, 71);
}

.services__container h1 {
  color: var(--white);
  font-family: 'Museo-Sans';
  font-size: 24px;
  letter-spacing: 4px;
}

.services__list-wrapper {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  margin: 2rem 2rem 1rem;
  gap: 1.5rem;
}

.services__list {
  align-self: flex-start;
  border-radius: 8px;
  border: 1px solid var(--white);
  padding: 1rem;
  background: rgb(71, 71, 71);
  color: var(--white);
  width: 100%;
}

.services__list-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
}

.services__list .service__title {
  font-family: 'Museo-Sans';
  font-size: 16px;
  text-transform: uppercase;
}

.services__list-content {
  height: 120px;
  text-align: left;
  padding-top: 1rem;
  margin-top: 1rem;
  font-family: 'Myriad-Regular';
  border-top: 1px solid var(--normal-grey);
}

.service__btn {
  text-align: right;
  margin-top: 1rem;
  font-size: 12px;
  font-weight: bold;
}

.service__btn span {
  padding-bottom: 3px;
  cursor: pointer;
  border-bottom: 1px solid var(--white);
}

@media screen and (min-width: 1237px) {
  .services__container {
    padding: 4rem 2rem 2rem;
  }
  .services__container h1 {
    font-size: 32px;
  }
  .services__list-content {
    min-height: 140px;
  }
  .services__list-wrapper {
    margin: 3rem 4rem 0;
    gap: 2rem;
    display: grid;
    grid-template-columns: repeat(4, 1fr);
  }
}

@media screen and (min-width: 767px) and (max-width: 1236px) {
  .services__list-wrapper {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
  }
}

@media screen and (max-width: 360px) {
  .services__list-content {
    min-height: 150px;
  }
}

@media screen and (max-width: 320px) {
  .services__list-content {
    min-height: 170px;
  }
}
