.carousel__container {
  padding-top: 3.5rem;
  background-color: rgb(37, 37, 37);
}

.slider__image {
  background-size: 100% 100%;
  background-repeat: no-repeat;
  width: 100%;
  height: 380px;
}

.topbar1 {
  background-image: url('assets/top-bar-1.png');
}

.topbar2 {
  background-image: url('assets/top-bar-2.png');
}

.topbar3 {
  background-image: url('assets/top-bar-3.png');
}

.topbar4 {
  background-image: url('assets/top-bar-4.png');
}

.swiper-pagination-bullet {
  background-color: var(--normal-grey) !important;
}

.swiper-pagination-bullet-active {
  background-color: var(--white) !important;
}

@media screen and (min-width: 461px) {
  .topbar1 {
    background-image: url('assets/top-bar-desktop-1.png');
  }
  .topbar2 {
    background-image: url('assets/top-bar-desktop-2.png');
  }
  .topbar3 {
    background-image: url('assets/top-bar-desktop-3.png');
  }
  .topbar4 {
    background-image: url('assets/top-bar-desktop-4.png');
  }
}

@media screen and (min-width: 1024px) {
  .carousel__container {
    padding-top: 0;
  }
  .slider__image {
    height: 520px;
  }
}
