.contactus__container {
  margin: 6rem 4rem;
  padding: 0 3rem;
  color: var(--white);
  line-height: 1.6;
  font-family: 'Myriad-Regular';
}

.contactus__container > section > h1 {
  letter-spacing: 4px;
  font-size: 24px;
  font-family: 'Museo-Sans';
}

.contactus__text {
  font-size: 16px;
  margin-top: 2rem;
}

.form__section {
  margin: 4rem 0;
}

.aboutus__row-input {
  display: flex;
  flex-direction: row;
  gap: 1.5rem;
}

.aboutus__input-wrapper {
  display: flex;
  flex-direction: column;
  font-size: 16px;
  margin-top: 1rem;
}

.aboutus__input-wrapper > span.error {
  color: red;
}

.aboutus__input-wrapper > input {
  width: 320px;
  height: 32px;
}

.message__input {
  width: 662px;
  height: 200px;
}

.form__section button {
  border: none;
  padding: 1rem;
  border-radius: 8px;
  width: 200px;
  margin-top: 1.5rem;
  background: var(--dark-red);
  color: var(--white);
  font-family: 'Museo-Sans';
  cursor: pointer;
  font-size: 16px;
}

.location {
  margin-bottom: 2rem;
}

.location h1 {
  margin-bottom: 2rem;
}

@media screen and (max-width: 1023px) {
  .contactus__container {
    margin: 6rem 2rem 0;
    padding: 0;
  }
  .aboutus__row-input {
    flex-direction: column;
    gap: 0;
  }
  .message__input {
    width: 100%;
  }
  .contact__headline {
    margin: 0 -2rem;
  }
  .contact__btn-wrapper {
    display: flex;
    justify-content: center;
  }
}

@media screen and (min-width: 1023px) {
  .contact__headline {
    margin: 0 -8rem;
  }
}
