.menu {
  display: flex;
  bottom: 0;
  left: 0;
  padding: 0;
  overflow: hidden;
  position: absolute;
  pointer-events: none;
  right: 0;
  top: 0;
  width: 100%;
  font-family: 'Museo-Sans';
  text-transform: capitalize;
}

.menu.menu__visible {
  pointer-events: visible;
  overflow: hidden;
  position: fixed;
  height: 100vh;
  z-index: 999;
}

.menu .menu__mask {
  display: none;
}

.menu.menu__visible .menu__mask {
  display: block;
  z-index: 99;
}

.menu__mask {
  background: rgba(0, 0, 0, 0.4);
  bottom: 0;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  z-index: 999;
  width: 100%;
}

.menu__body {
  background: #fff;
  height: 100vh;
  z-index: 100;
  position: fixed;
  top: 0;
  bottom: 0;
  display: flex;
  width: 65%;
  padding-bottom: 2.5rem;
  animation-name: slideInRight;
  animation-duration: 0.5s;
}

.menu__wrapper {
  width: 100%;
  overflow: scroll;
}

.menu__header {
  display: flex;
  position: sticky;
  padding: 0.9rem;
  top: 0;
  justify-content: space-between;
  background-color: var(--white);
  color: var(--main-red);
  border-bottom: 2px solid #949494;
}

.menu__wrapper .menu__list-wrapper:last-child {
  margin-bottom: 0.9rem;
}

.menu__list-wrapper {
  text-indent: 0.5rem;
  padding: 0.5rem 0.5rem 0 0;
}

.menu__list {
  padding: 0.5rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.sub__menu-list span {
  display: block;
}

.menu__list span:first-child,
.sub__menu-list span {
  width: 100%;
}

.menu__list.active,
.sub__menu-list.active {
  background-color: var(--main-red);
  border-radius: 0 30px 30px 0;
}

.menu__list.active span,
.sub__menu-list.active span {
  color: var(--white);
}

.sub__menu {
  display: none;
}

.sub__menu.active {
  text-indent: 2rem;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.menu__body.disabled {
  animation-name: slideOutRight;
  animation-duration: 0.5s;
}

.sub__menu-list {
  padding: 0.5rem;
}

@keyframes slideInRight {
  from {
    transform: translateX(-100%);
  }
  to {
    transform: translateX(0%);
  }
}

@keyframes slideOutRight {
  from {
    transform: translateX(0%);
  }
  to {
    transform: translateX(-100%);
  }
}
