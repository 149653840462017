.clients__container {
  margin: 6rem 2rem 0 2rem;
}

.clients__container p {
  text-align: center;
  color: var(--white);
  font-size: 16px;
  font-family: 'Myriad-Regular';
  line-height: 1.6;
}

.clients__logo-wrapper {
  margin-top: 2rem;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 0.5rem;
}

.clients__logo-item {
  width: 100%;
  border-radius: 10px;
  background-color: rgb(189, 191, 193);
  padding: 2rem 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 144px;
}

.clients__logo-item img {
  width: 100%;
  max-height: 80px;
}

.clients__headline-text {
  margin: 4rem -2rem 0;
}

.clients__logo-item:nth-child(12) img {
  width: 80px;
  min-height: 120px;
}

.clients__logo-item:nth-child(25) img {
  width: 60px;
}

.clients__logo-item:nth-child(23) img {
  width: 100px;
}

@media screen and (min-width: 1023px) {
  .clients__container {
    margin: 6rem 16rem;
  }
  .clients__logo-wrapper {
    grid-template-columns: repeat(4, 1fr);
    gap: 1rem;
  }
  .clients__logo-item {
    padding: 2rem;
  }
  .clients__logo-item:nth-child(3) img {
    width: 120px;
    height: auto;
  }
  .clients__logo-item:nth-child(12) {
    padding: 0.5rem;
  }
  .clients__logo-item:nth-child(12) img {
    width: 80px;
    min-height: 120px;
  }
  .clients__logo-item:nth-child(16) img {
    width: 120px;
    min-height: 120px;
  }
  .clients__headline-text {
    margin: 4rem -16rem 0;
  }
}
