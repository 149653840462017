.workshop__container {
  margin: 6rem 4rem;
  color: var(--white);
}

.workshop__top-desc {
  text-align: center;
  font-size: 16px;
  font-family: 'Myriad-Regular';
  line-height: 1.6;
}

.workshop__image-wrapper {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 2rem;
  margin: 4rem 0;
}

.workshop__image-wrapper img {
  cursor: pointer;
}

.workshop__headline-text {
  margin: 12rem -4rem -6rem -4rem;
}

@media screen and (max-width: 1023px) {
  .workshop__container {
    margin: 6rem 2rem 0 2rem;
  }
  .workshop__image-wrapper {
    grid-template-columns: repeat(2, 1fr);
    gap: 1rem;
  }
  .workshop__top-desc {
    text-align: center;
    text-indent: 0;
  }
  .workshop__headline-text {
    margin: 0 -2rem;
  }
}
