.project__container {
  margin: 6rem 4rem;
  color: var(--white);
}

.project__top-desc {
  text-align: center;
}

.project__top-desc p {
  font-size: 16px;
  font-family: 'Myriad-Regular';
  line-height: 1.6;
}

.project__top-desc h4 {
  font-size: 20px;
  font-family: 'Myriad-Regular';
  line-height: 1.6;
}

.project__card-wrapper {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  margin: 4rem 0;
  gap: 3rem;
}

.project__card {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.project__btn {
  display: flex;
  justify-content: center;
}

.project__btn div {
  cursor: pointer;
}

.project__card img {
  margin-top: 1rem;
}

.project__card .project__btn div {
  padding: 0.5rem 2rem;
  border-radius: 30px;
  width: fit-content;
  background: var(--dark-red);
  font-family: 'Museo-Sans';
}

.project__headline-text {
  margin: 12rem -4rem -6rem -4rem;
}

@media screen and (min-width: 1023px) {
  .project__card:last-child {
    grid-column-start: 2;
    grid-column-end: 3;
  }
}

@media screen and (min-width: 767px) and (max-width: 1023px) {
  .project__card-wrapper {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media screen and (max-width: 767px) {
  .project__container {
    margin: 6rem 2rem 0 2rem;
  }
  .project__card-wrapper {
    grid-template-columns: repeat(1, 1fr);
  }
  .project__top-desc {
    text-indent: 0;
    text-align: center;
  }
  .project__card img {
    border: 6px solid #fff;
    border-radius: 30px;
  }
  .project__headline-text {
    margin: 0 -2rem;
  }
}
