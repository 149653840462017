.client__container {
  background: transparent;
  padding: 0 1.5rem 2rem;
}

.client__title {
  font-family: 'Museo-Sans';
  color: var(--white);
  font-size: 24px;
  letter-spacing: 4px;
  text-align: center;
  margin: 2rem 0;
}

.client__item {
  display: flex;
  align-items: center;
  justify-content: center;
  /* width: 90px; */
  height: 80px;
  border-radius: 6px;
  background: rgb(189, 191, 193);
  padding: 1rem 0.5rem;
}

.client__item-img {
  max-width: 80px;
  height: auto;
}

.client__item-img.bfi {
  max-width: 60px;
  height: auto;
}

.client__item-img.citrakarya,
.client__item-img.djp {
  height: 50px;
}

.client__item-img.bintaro-parkview,
.client__item-img.vivo,
.client__item-img.stmik-global {
  height: 70px;
}

.client__container :global .swiper-button-next,
.client__container :global .swiper-button-prev {
  margin: -1.5rem -1rem;
}

@media screen and (min-width: 1023px) {
  .client__container {
    padding: 2rem 1.5rem 4rem;
  }
  .client__title {
    font-size: 32px;
    padding-bottom: 2rem;
  }
  .client__item {
    /* width: 220px; */
    height: 180px;
  }
  .client__item-img {
    max-width: 200px;
  }
  .client__item-img.bfi {
    max-width: 130px;
  }
  .client__item-img.citrakarya {
    height: auto;
  }
  .client__item-img.bintaro-parkview {
    max-width: 100px;
    height: 100%;
  }
  .client__item-img.vivo,
  .client__item-img.stmik-global {
    max-width: 130px;
    height: auto;
  }
  .client__item-img.djp {
    max-width: 80px;
    height: auto;
  }
  .client__container :global .swiper-button-next,
  .client__container :global .swiper-button-prev {
    margin: -1.5rem 0;
  }
}
