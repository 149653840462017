.detail__subproduct-wrapper {
  margin: 4rem 0 0;
  display: grid;
  font-size: 16px;
  line-height: 1.6;
  gap: 4rem;
  font-family: 'Myriad-Regular';
  grid-template-columns: repeat(2, 1fr);
}

.subproduct__item-wrapper {
  display: flex;
  padding: 2rem 4rem;
  border-radius: 8px;
  justify-content: flex-start;
  border: 1px solid var(--white);
}

.subproduct__item-name {
  font-weight: bold;
  font-family: 'Myriad-Regular';
  letter-spacing: 4px;
}

.subproduct__item-name p {
  margin-bottom: 0.5rem;
}

.detail__subproduct-wrapper ul {
  list-style: none;
  font-weight: normal;
  letter-spacing: normal;
  font-family: 'Myriad-Regular';
}

.detail__subproduct-wrapper li::before {
  content: '\2022'; /* Add content: \2022 is the CSS Code/unicode for a bullet */
  color: var(--white);
  font-weight: bold;
  font-size: 24px;
  display: inline-block; /* Needed to add space between the bullet and the text */
  width: 1em; /* Also needed for space (tweak if needed) */
  margin-left: -1em; /* Also needed for space (tweak if needed) */
}

.subproduct__circle {
  width: 236px;
  height: 236px;
  background-color: #ededed;
  border-radius: 50%;
}

@media screen and (max-width: 767px) {
  .detail__subproduct-wrapper {
    margin: 4rem 0;
    row-gap: 2rem;
    grid-template-columns: repeat(1, 1fr);
  }
  .subproduct__item-wrapper {
    margin: 0;
  }
}

@media screen and (min-width: 1023px) {
  .subproduct__item-name p {
    white-space: nowrap;
  }
}
