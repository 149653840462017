.footer_container {
  display: flex;
  position: relative;
  padding: 3rem 0 5rem 3rem;
  height: auto;
  color: var(--white);
}

.footer__desc-wrapper,
.footer__contact-wrapper {
  max-width: 32%;
}

.footer__desc-wrapper > label,
.footer__contact-wrapper > label {
  font-family: 'Myriad-Bold';
}

.footer__desc-wrapper .footer__desc {
  margin: 2rem 0;
  font-family: 'Myriad-Regular';
}

.sosial__media-icon {
  display: flex;
  gap: 0.5rem;
}

.sosial__media-icon img:last-child {
  margin-left: 0.5rem;
}

.sosial__media-icon img {
  max-width: 18px;
}

.footer__contact-wrapper {
  padding: 0 1.5rem;
  margin-left: 3rem;
  border-left: 1px solid var(--white);
}

.contact__icon {
  margin-top: 1rem;
  display: flex;
  align-items: center;
}

.contact__icon:first-child {
  align-items: start;
}

.contact__icon:first-child > p {
  margin: 0.5rem 0 0 0.5rem;
}

.contact__icon:not(:first-child) > p {
  margin-left: 0.5rem;
}

.contact__icon > p {
  font-size: 16px;
  font-family: 'Myriad-Regular';
}

.copyright {
  position: absolute;
  bottom: 0;
  width: 100%;
  text-align: center;
  padding: 0.8rem 0;
  margin: 2rem -2rem 0 -3rem;
  background: rgb(71, 71, 71);
  font-size: 12px;
}

.copyright__icon {
  font-size: 8px !important;
  border-radius: 50%;
  padding: 0 1.5px;
  border: 1px solid var(--white);
  vertical-align: middle;
}

@media screen and (max-width: 767px) {
  .footer_container {
    flex-direction: column;
    padding: 2rem 2rem 0;
  }
  .footer__contact-wrapper {
    border-left: none;
    margin: 2rem 0 0;
    padding: 0;
  }
  .footer__desc-wrapper,
  .footer__contact-wrapper {
    max-width: 100%;
  }
  .sosial__media-icon {
    gap: 1.5rem;
  }
  .sosial__media-icon img:last-child {
    margin-left: 0;
  }
  .contact__icon:first-child > p {
    margin: 0.4rem 0 0 0.5rem;
  }
  .copyright {
    position: relative;
    width: auto;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
