.whatsapp__widget-container {
  display: flex;
  align-items: center;
  position: fixed;
  right: 3%;
  bottom: 3%;
  z-index: 100;
  cursor: pointer;
}

.whatsapp__widget-container div {
  background-color: var(--white);
  font-size: 14px;
  font-family: 'Myriad-bold';
  padding: 0.5rem 0.8rem 0.5rem 1rem;
  border-radius: 50px 0 0 50px;
}

.whatsapp__widget-container img {
  margin-left: -0.6rem;
  width: 58px;
  height: auto;
}

.whatsapp_link {
  color: #000;
}

@media screen and (max-width: 1023px) {
  .whatsapp__widget-container div {
    display: none;
  }
}
