.headline__text {
  padding: 1rem;
  text-align: center;
  color: var(--white);
  background: var(--dark-red);
}

.headline__text > span {
  font-family: 'Museo-Sans';
  letter-spacing: 3px;
  word-spacing: 2px;
  text-transform: uppercase;
}
