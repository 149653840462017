.philosophy__container {
  padding-top: 3.5rem;
  line-height: 1.6;
  background-color: #767575;
}

.slider__office {
  background-size: 100% 100%;
  background-repeat: no-repeat;
  width: 100%;
  height: 220px;
}

.office1 {
  background-image: url('./assets/office-1.png');
}

.office2 {
  background-image: url('./assets/office-2.png');
}

.office3 {
  background-image: url('./assets/office-3.png');
}

.philosophy__slider,
.illust {
  margin-bottom: 2rem;
}

.philosophy__content {
  margin: 0 2rem;
}

.philosophy__content h1,
.vision__mission h1 {
  text-align: center;
  color: var(--white);
  font-family: 'Museo-Sans';
  font-size: 24px;
  letter-spacing: 4px;
}

.philosophy__history {
  margin: 3rem 0 2rem;
}

.philosophy__history span {
  color: var(--white);
  font-family: 'Museo-Sans';
  font-size: 18px;
}

.philosophy__history p {
  margin-top: 1rem;
  font-family: 'Myriad-Regular';
  font-size: 16px;
}

.philosophy__history-item:not(:first-child),
.philosophy__footer-img {
  margin-top: 2.5rem;
}

.philosophy__service {
  font-family: 'Myriad-Regular';
  color: var(--white);
  padding: 2rem;
  background-color: rgb(58, 58, 58);
}

.vision__mission {
  display: flex;
  flex-direction: column;
  padding: 2rem;
  gap: 1.5rem;
}

.vision__mission img {
  align-self: center;
  width: 220px;
  height: auto;
}

.vision__mission-list span {
  display: flex;
  flex-direction: column;
  padding-left: 1rem;
}

.vision__mission-list ul {
  list-style: none;
}

.vision__mission-list li::before {
  content: '\2022'; /* Add content: \2022 is the CSS Code/unicode for a bullet */
  color: var(--dark-red);
  font-weight: bold;
  font-size: 24px;
  display: inline-block; /* Needed to add space between the bullet and the text */
  width: 1em; /* Also needed for space (tweak if needed) */
  margin-left: -1em; /* Also needed for space (tweak if needed) */
}

@media screen and (min-width: 1023px) {
  .philosophy__container {
    padding-top: 0;
  }
  .philosophy__content h1 {
    font-size: 32px;
  }
  .philosophy__content {
    padding: 0 6rem;
    margin: 0 12rem;
  }
  .slider__office {
    height: 620px;
    background-position: center;
  }
  .philosophy__service {
    padding: 2rem 18rem;
  }
  .vision__mission img {
    width: 320px;
    height: auto;
  }
  .vision__mission-list {
    width: 650px;
    margin: 0 auto;
  }
  .vision__mission-list span {
    padding-left: 6rem;
  }
}

@media screen and (min-width: 767px) and (max-width: 1023px) {
  .vision__mission-list {
    width: 460px;
    margin: 0 auto;
  }
}
