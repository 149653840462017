.loader__container {
  margin-top: 4rem;
  display: flex;
  justify-content: center;
}
.loader {
  margin: auto;
  border: 10px solid #eaf0f6;
  border-radius: 50%;
  border-top: 10px solid var(--dark-red);
  width: 120px;
  height: 120px;
  animation: spinner 1.6s linear infinite;
}

@keyframes spinner {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

/* For medium devices */
@media screen and (min-width: 767px) and (max-width: 1023px) {
  .loader__container {
    margin-top: 1rem;
  }
}

/* For small devices */
@media screen and (max-width: 767px) {
  .loader {
    width: 60px;
    height: 60px;
  }
  .loader__container {
    margin-top: 0.5rem;
  }
}
