.projects__container {
  background-color: rgb(71, 71, 71);
  padding: 3rem 1.5rem 5rem;
}

.project__title {
  border-top: 1px solid var(--white);
  text-align: center;
  color: var(--white);
  margin: 0 -1.5rem 2rem;
  padding-top: 4rem;
  letter-spacing: 4px;
  font-size: 32px;
  font-family: 'Museo-Sans';
}

.project__text {
  margin: 2rem 1.5rem;
  text-align: center;
}

.project__text p {
  font-size: 16px;
  font-family: 'Myriad-Regular';
  color: var(--white);
  line-height: 1.6;
}

.project__text h4 {
  font-size: 20px;
  font-family: 'Myriad-Regular';
  line-height: 1.6;
  color: var(--white);
}

.border__projects-img {
  display: flex;
  justify-content: center;
  align-items: center;
}

.border__projects-img img {
  border-radius: 20px;
}

.projects__title {
  margin-top: 1.5rem;
  font-family: 'Museo-Sans';
  font-size: 20px;
  padding-right: 48px;
  text-align: center;
  color: var(--white);
}

@media screen and (max-width: 767px) {
  .projects__container {
    padding: 0 1.5rem 2rem;
  }
  .project__title {
    font-size: 24px;
    padding-top: 2rem;
  }
  .border__projects-img {
    width: 100%;
    height: auto;
    padding: 0 1.5rem;
  }
  .border__projects-img img {
    width: 100%;
    border: 4px solid var(--white);
  }
  .projects__container :global .swiper-button-next,
  .projects__container :global .swiper-button-prev {
    margin: -2.5rem -1rem;
  }
  .projects__title {
    padding-right: 0;
  }
}
