.nav__container {
  position: sticky;
  display: flex;
  align-items: center;
  padding: 0.2rem 6rem;
  border-radius: 0 0 18px 18px;
  background-image: url('assets/navbar-background.png');
  background-size: cover;
  top: 0;
  width: 100%;
  z-index: 99;
}

.nav__logo {
  max-width: 260px;
  height: auto;
  background: transparent;
}

.nav__menu {
  position: absolute;
  display: flex;
  align-items: center;
  gap: 2.5rem;
  margin-right: 2rem;
  right: 0;
  border-radius: 50px;
  font-size: 14px;
  background: linear-gradient(90deg, var(--main-red), var(--dark-red));
}

.nav__menu-mobile {
  display: none;
}

.nav__menu-list {
  display: flex;
  align-items: center;
  padding: 1rem 0;
  text-transform: uppercase;
  color: #bdbdbd;
  cursor: pointer;
  font-family: 'Museo-Sans';
  font-weight: normal;
}

.nav__menu-list.active {
  color: var(--white);
  font-weight: bolder;
}

.nav__menu-list:first-child {
  margin-left: 2.5rem;
}

.nav__menu-list:last-child {
  margin-right: 2.5rem;
}

.nav__submenu-arrow {
  margin-left: 0.5rem;
}

.dropdown__wrapper {
  max-width: 120px;
  background: var(--white);
  position: absolute;
  top: 100%;
  border: 1px solid #ededed;
}

.dropdown__wrapper.about_us {
  left: 15%;
}

.dropdown__wrapper.projects {
  left: 33.5%;
}

.dropdown__options {
  padding: 1rem 0.5rem;
  cursor: default;
}

.dropdown__options:not(:first-child) {
  border-top: 1px solid #ededed;
}

.dropdown__options .radio {
  display: none;
}

.dropdown__options label {
  cursor: pointer;
  color: var(--main-black);
}

.dropdown__options label:hover,
.dropdown__options label.active {
  color: var(--main-red);
}

@media screen and (min-width: 768px) and (max-width: 1161px) {
  .nav__container {
    padding: 0 2rem;
  }
  .nav__menu {
    gap: 1.5rem;
  }
}

@media screen and (max-width: 1023px) {
  .nav__container {
    position: fixed;
    padding: 0.1rem 1.5rem;
    justify-content: space-between;
  }
  .nav__logo {
    width: 160px;
  }

  .nav__menu-mobile {
    display: block;
  }
}
