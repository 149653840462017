/* ================== VARIABLE CSS ================== */
:root {
  /* ======= Colors ======= */
  --main-red: #b62227;
  --dark-red: #800d0e;
  --white: #fff;
  --main-black: #24292e;
  --normal-grey: #ededed;
  --text-grey: #a9aaac;
}

/* ================== BASE ================== */
* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}

body {
  background-color: var(--main-black);
}

html {
  scroll-behavior: smooth;
  overflow-x: hidden;
}

img {
  max-width: 100%;
  height: auto;
}

/* ================== FONTS ================== */
@font-face {
  font-family: 'Museo-Sans';
  src: local('MuseoSans_700'),
    url('./fonts/MuseoSans_700.otf') format('truetype');
}

@font-face {
  font-family: 'Myriad-Regular';
  src: local('MYRIADPRO-REGULAR'),
    url('./fonts/MYRIADPRO-REGULAR.OTF') format('truetype');
}

@font-face {
  font-family: 'Myriad-Bold';
  src: local('MYRIADPRO-BOLD'),
    url('./fonts/MYRIADPRO-BOLD.OTF') format('truetype');
}
