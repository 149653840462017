.project__detail-container {
  margin: 6rem 4rem;
  color: var(--white);
}

.project__detail-title {
  text-align: center;
  color: var(--white);
  font-family: 'Museo-Sans';
  font-size: 24px;
  letter-spacing: 4px;
  margin-bottom: 2rem;
}

.project__detail-desc {
  font-size: 16px;
  text-align: center;
  font-family: 'Myriad-Regular';
  line-height: 1.6;
}

.project__detail-images-wrapper {
  margin: 4rem 0 0;
  display: grid;
  gap: 1rem;
  grid-template-columns: repeat(2, 1fr);
}

@media screen and (max-width: 767px) {
  .project__detail-container {
    margin: 6rem 2rem;
  }
  .project__detail-images-wrapper {
    grid-template-columns: repeat(1, 1fr);
  }
}
